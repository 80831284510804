<template>
  <div class="d-flex flex-column align-items-center">
    <div class="app-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <transition name="slide-burger-menu">
        <div class="menu-container" v-if="burgerMenuVisible">
          <div class="d-flex flex-column justify-content-center">
            <div class="menu-option-container col-9">
              <div>
                <div @click="routeToPage('landing')">HOME</div>
                <img class="col-12 line-img" src="./assets/horizontal-line.png">
              </div>
              <div>
                <div @click="routeToPage('leaderboard')">LEADERBOARD</div>
                <img class="col-12 line-img" src="./assets/horizontal-line.png">
              </div>
              <div>
                <div @click="routeToPage('rewards')">REWARDS</div>
                <img class="col-12 line-img" src="./assets/horizontal-line.png">
              </div>
              <div>
                <div @click="routeToPage('manage-subscription')">MANAGE SUBSCRIPTION</div>
                <img class="col-12 line-img" src="./assets/horizontal-line.png">
              </div>
              <div>
                <div @click="termsConditionsClicked()">T'S & C'S</div>
                <img class="col-11 line-img" src="./assets/horizontal-line.png">
              </div>
              <div>
                <div @click="routeToPage('create-username')">EDIT USERNAME</div>
                <img class="col-12 line-img" src="./assets/horizontal-line.png">
              </div>
              <div @click="burgerMenuClicked()" class="d-flex flex-column align-items-center mt-5">
                <img class="col-1 line-img" src="./assets/close-tab-icon.png">
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="`d-flex flex-column header-container-${this.bodyState}`">
        <div v-if="bodyState === 'leaderboard'" class="d-flex flex-row col-12 leaderboard-header-container">
          <img v-if="burgerMenuIconVisible" @click="burgerMenuClicked()" class="col-1 m-3" src="./assets/burger-menu-icon.png">
          <img class="col-2 stop-clock-leaderboard-image" v-if="bodyState === 'leaderboard'" :src="require(`./brand/${brandConfig.brand}/assets/stop-the-clock-logo.png`)"/>
        </div>
        <div v-if="bodyState === 'default'" class="d-flex flex-row col-12 clock-default-container">
          <img v-if="burgerMenuIconVisible" @click="burgerMenuClicked()" class="col-1 m-3 burger-menu-image" src="./assets/burger-menu-icon.png">
          <img class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-4 clock-default-image" :src="require(`./brand/${brandConfig.brand}/assets/stop-the-clock-logo.png`)"/>
        </div>
      </div>
      <div v-bind:class="`content-container-${this.bodyState}`">
        <LoadingOverlay v-if="this.isLoading"/>
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingOverlay from "@/views/components/LoadingOverlay";
import {mapState, mapMutations} from "vuex";
import {setBrandConfig} from "@/functions/setBrandConfig";
import DcbService from "@/services/dcbService";
import {environmentConfig} from "@/config/enviromentConfig";
import {Brands} from "./brand/brandConfig";
import {embedEncryptedMsisdnEvinaScript} from "./functions/embedEncryptedMsisdnEvinaScript";

export default {
  components: {LoadingOverlay},
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'burgerMenuIconVisible', 'msisdn'])
  },
  data() {
    return {
      bodyState: undefined,
      burgerMenuVisible: false,
    }
  },
  beforeMount() {
    setBrandConfig();
    this.setTabName();
    this.injectTealium();
  },
  methods: {
    ...mapMutations(['setEvinaToken', 'setEvinaScript']),
    burgerMenuClicked() {
      this.burgerMenuVisible = !this.burgerMenuVisible;
    },
    routeToPage(pageRoute) {
      this.burgerMenuVisible = !this.burgerMenuVisible;
      this.$router.push(`/${pageRoute}`);
    },
    async termsConditionsClicked() {
      this.burgerMenuVisible = !this.burgerMenuVisible;
      let redirectUrl;
      if (this.brandConfig.brand === Brands.VodacomEvinaSouthAfricaEnglish) {
        redirectUrl = environmentConfig.quizIncTermsConditionsHub;
        redirectUrl += `/?e-msisdn=${this.token}`;
      } else {
        await this.encryptMsisdn()
        redirectUrl = environmentConfig.quizIncTermsConditionsHub;
        if (this.encryptedMsisdn) {
          redirectUrl += `/?e-msisdn=${this.encryptedMsisdn}`;
        }
      }
      window.open(redirectUrl, '_self');
    },
    async encryptMsisdn() {
      let encryptMsisdnResponse = await DcbService.encryptUserMsisdn(this.msisdn)
      this.encryptedMsisdn = encryptMsisdnResponse.data.encryptedMsisdn;
    },
    setTabName() {
      document.title = this.brandConfig.productTitle;
    },
    embedEvinaEncryptedMsisdnScript() {
      const evinaScriptResponse = DcbService.getEvinaScript(".click-button", this.encryptedMsisdn);
      this.setEvinaParameters(evinaScriptResponse.evinaToken, evinaScriptResponse.script);
      embedEncryptedMsisdnEvinaScript(evinaScriptResponse.script);
    },
    setEvinaParameters(evinaToken, evinaScript) {
      this.setEvinaToken(evinaToken);
      localStorage.setItem('evinaToken', evinaToken);

      this.setEvinaScript(evinaScript);
      localStorage.setItem('evinaScript', evinaScript);
    },
    injectTealium() {
      (function (a, b, c, d) {
        a = 'https://tags.tiqcdn.com/utag/vodafone/za-quizinc-main/prod/utag.js';
        b = document;
        c = 'script';
        d = b.createElement(c);
        d.src = a;
        d.type = 'text/java' + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
      })();
    }
  },
  watch: {
    async $route(to, from) {
      const routePath = this.$route.path;
      const isLeaderboardPath = ['/leaderboard', '/welcome'].includes(routePath);
      const injectVodacomEvina = this.brandConfig.brand === Brands.VodacomEvinaSouthAfricaEnglish;

      if (isLeaderboardPath) this.bodyState = 'leaderboard';
      else this.bodyState = 'default';
      if (injectVodacomEvina) await this.embedEvinaEncryptedMsisdnScript();
    }
  }
};
</script>
<style>

.app-container {
  height: 100vh;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.header-container-default {
  z-index: 0;
  height: 28%;
  background-image: url("./assets/main-banner.png");
  background-size: cover;
  background-position: bottom 25px center;
}

.header-container-leaderboard {
  z-index: 0;
  height: 20%;
  background-image: url("./assets/main-banner.png");
  background-size: cover;
  background-position: bottom 25px center;
}

.content-container-default {
  z-index: 1;
  background: #1c1c1c;
  height: 85vh;
  margin-top: -55px;
  border-top-left-radius: 315% 18%;
  border-top-right-radius: 315% 18%;
}

.content-container-leaderboard {
  z-index: 1;
  background: #1c1c1c;
  background-size: contain;
  margin-top: -55px;
  border-top-left-radius: 315% 18%;
  border-top-right-radius: 315% 18%;
}

.leaderboard-header-container {
  position: relative;
}

.clock-default-container {
  position: relative;
  height: 88%;
}

.clock-default-image {
  position: absolute;
  left: 0;
  right: 0;
  top: -15px;
  bottom: 0;
  margin: auto;
}

.stop-clock-leaderboard-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  bottom: 0;
  margin: auto;
}

.burger-menu-image {
  position: absolute;
}

.menu-container {
  position: absolute;
  height: 100%;
  width: 350px;
  z-index: 1;
  font-family: "Komikax", sans-serif;
  font-size: 16px;
  border-radius: 0 0 0 0;
  background-color: rgba(0, 0, 0, .75);
  color: #FFFFFF;
}

.menu-option-container {
  position: absolute;
  top: 35%;
  left: 30px;
}

.slide-burger-menu-leave-active, .slide-burger-menu-enter-active {
  transition: 1s;
  z-index: 1;
}

.slide-burger-menu-enter-from {
  transform: translateY(-100%);
}

.slide-burger-menu-leave-to {
  transform: translateY(-100%);
}

</style>
