export const brandConfigObjectMap = new Map();

export const Brands = {
    VodacomSouthAfricaEnglish: 'vc-za-en',
    VodacomEvinaSouthAfricaEnglish: 'vc-encrypt-za-en',
};

export const ServiceId = 'vc-fanclash-quizinc-03';

brandConfigObjectMap.set(Brands.VodacomSouthAfricaEnglish, {
    productId: 3,
    productTitle: 'Stop The Clock',
    categoryId: null,
    showTermsConditions: true,
    showDataWon: true,
    serviceId: 'vc-fanclash-quizinc-03',
    quizIncAllAccessServiceId: 'vc-fanclash-quizinc-02',
    newIntegration: false,
    baseRoute: '/welcome'
});

brandConfigObjectMap.set(Brands.VodacomEvinaSouthAfricaEnglish, {
    productId: 3,
    productTitle: 'Stop The Clock',
    categoryId: null,
    showTermsConditions: true,
    showDataWon: true,
    quizIncAllAccessServiceId: 'vc-fanclash-quizinc-02',
    newIntegration: true,
    baseRoute: '/opt-in-confirmation'
});
